<template>
  <div class="container my-5">
    <b-card class="text-center p-4 shadow border-0">
      <h2 class="mb-4">
        {{ $t("delete_your_account") }}
      </h2>
      <p>{{ $t('please_let_us_know_the_reason_you_leave') }}</p>

      <b-form @submit.prevent="showModal">
        <b-form-group>
          <b-form-radio-group
            v-model="selectedReason"
            :options="reasonOptions"
            name="reasonRadios"
            class="mb-3"
            stacked
          />
        </b-form-group>

        <b-button
          variant="danger"
          :disabled="!!!selectedReason && sendOtpLoading"
          @click="showModal"
        >

          <span v-if="!sendOtpLoading">
            {{ $t('delete_your_account') }}
          </span>
          <b-spinner
            v-else
            small
            size="10"
            variant="light"
            type="grow"
          />
        </b-button>
      </b-form>
    </b-card>

    <b-modal
      v-model="otpModal"
      :title="$t('confirm_delete_account')"
      centered
      size="md"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer
        #default="{invalid}"
      >
        <b-row>
          <b-col>
            <div
              v-if="mobile"
              class="my-1"
            >
              {{ $t('delete_account_confirm_description', { mobile }) }}
            </div>
            <b-form
              class="auth-login-form mb-2"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="code">{{ $t('confirm_delete_code') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('confirm_delete_code')"
                  vid="code"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="code"
                      v-model="confirmCode"
                      name="code"
                      placeholder="0000"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-button
              block
              size="md"
              variant="warning"
              :disabled="verifyCounter !== 0"
              @click="sendOtp(true)"
            >
              <div v-if="!resendOtpLoading">
                <span>
                  {{ $t('resend') }}
                </span>
                <span v-if="verifyCounter !== 0">({{ verifyCounter }})</span>
              </div>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              size="md"
              :disabled="invalid || confirmCode.length < 4"
              variant="success"
              @click="deleteAccount"
            >
              <span v-if="!verifyLoading">
                {{ $t('widrawModal.confirm') }}
              </span>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import ProfileApis from '@/services/apis/profile'

const profile = new ProfileApis()

export default {
  data() {
    return {
      selectedReason: null,
      password: '',
      otpModal: false,
      confirmCode: '',
      verifyCounter: 0,
      resendOtpLoading: false,
      sendOtpLoading: false,
      verifyLoading: false,

    }
  },
  computed: {
    mobile() {
      return this.$store.state.profile.profileInfo.mobile
    },
    reasonOptions() {
      return [
        { text: this.$t('i_have_another_account'), value: 'i_have_another_account' },
        { text: this.$t('privacy_concerns'), value: 'privacy_concerns' },
        { text: this.$t('cant_find_what_i_need'), value: 'cant_find_what_i_need' },
        { text: this.$t('too_distracting'), value: 'too_distracting' },
        { text: this.$t('my_account_was_hacked'), value: 'my_account_was_hacked' },
        { text: this.$t('other_not_specified_here'), value: 'other_not_specified_here' },
      ]
    },
  },
  methods: {
    showModal() {
      if (this.selectedReason) {
        this.sendOtpLoading = true
        this.sendOtp().then(() => {
          this.otpModal = true
        }).catch(error => {
          if (error.status === 428) {
            this.otpModal = true
          }
        })
          .finally(() => {
            this.sendOtpLoading = false
          })
      }
    },
    async sendOtp(isResend) {
      if (isResend) {
        this.resendOtpLoading = true
      }
      return await profile.sendDeleteOtp().then(({ data }) => {
        this.verifyCounter = 60
        this.countDownTimer()
        this.$swal({
          title: data?.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.resendOtpLoading = false
      })
    },
    countDownTimer() {
      if (this.verifyCounter > 0) {
        setTimeout(() => {
          this.verifyCounter -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    deleteAccount() {
      this.verifyLoading = true
      profile.deleteAccount({ code: this.confirmCode }).then(({ data }) => {
        this.$swal({
          title: data?.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      }).finally(() => {
        this.verifyLoading = false
      })
    },
  },
}
</script>

  <style scoped>
  .container {
    max-width: 600px;
    margin: 0 auto;
  }
  .b-card {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .b-modal {
    max-width: 500px;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
  </style>
